import React from 'react';
import './App.scss';

interface FileContentDto {
  content: string;
}

const baseUrl = process.env.NODE_ENV === "development" ? "https://localhost:7247" : "https://api-pdf.oc6.dk";

let apiUrl: string = baseUrl + "/api/File/";

function App(): JSX.Element {
  const fileRef = React.useRef<HTMLInputElement>(null);
  const [files, setFiles] = React.useState<Array<File>>([]);

  function toSize(size: number): string {
    if (size >= 1024 ** 1) {
      if (size >= 1024 ** 2) {
        if (size >= 1024 ** 3) {
          return rounded(size / (1024 ** 3)) + " GB";
        }
        else {
          return rounded(size / (1024 ** 2)) + " MB";
        }
      }
      else {
        return rounded(size / (1024 ** 1)) + " KB";
      }
    }
    else {
      return size + " B";
    }
  }

  function rounded(size: number): number {
    return Math.round(size * 100) / 100;
  }

  function uploadImageClicked(file: File) {
    readFile(file, (result: string) => uploadImage(result));
  }

  async function uploadImage(content: string): Promise<any> {
    await upload("favicon.ico", content, apiUrl + "Ico");
  }

  function uploadPdfClicked(file: File, pages: number) {
    readFile(file, (result: string) => uploadPdf(file.name, pages, result));
  }

  function readFile(file: File, method: (result: string) => void) {
    let reader = new FileReader();

    reader.onload = function () {
      const { result } = reader;

      if (!result) {
        onError("No data");
      }
      else {
        method(result as string);

        removeFile(file);
      }
    };

    reader.onerror = () => onError(reader.error?.message ?? "N/A");

    function onError(error: string) {
      console.log(error);
    };

    reader.readAsDataURL(file);

    return true;
  }

  function removeFile(file: File) {
    setFiles(files.filter(f => !(f.name === file.name
      && f.size === file.size
      && f.type === file.type)));
  }

  async function uploadPdf(name: string, pages: number, content: string): Promise<any> {
    await upload(name, content, apiUrl + "Pdf/" + pages);
  }

  async function upload(name: string, content: string, url: string): Promise<any> {
    const data: FileContentDto = {
      content: content
    };

    const response: Response = await window.fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json"
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data)
    });

    const newData: FileContentDto = await response.json();

    download(newData, name);
  }

  function download(newData: FileContentDto, name: string): void {
    const a = document.createElement("a");
    a.href = newData.content;
    a.setAttribute(
      'download',
      "new_" + name,
    );
    document.body.appendChild(a);
    a.click();
    a.parentNode?.removeChild(a);
  }

  function imageClicked() {
    if (fileRef.current) {
      fileRef.current.click();
    }
  }

  function fileChanged(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.currentTarget.files) {
      const loadedFiles: Array<File> = [...event.currentTarget.files];

      if (loadedFiles && loadedFiles.length > 0) {
        setFiles(loadedFiles);
      }
      else {
        setFiles([]);
      }
    }
    else {
      setFiles([]);
    }
  }

  function renderFile() {
    return (
      <>
        {files.map((file, index) => {
          const { type } = file;
          const size = toSize(file.size);

          switch (type) {
            case "application/pdf": {
              return <PdfFileView
                key={"file_" + index}
                size={size}
                upload={(file: File, pages: number) => uploadPdfClicked(file, pages)}
                file={file} />;
            }
            case "image/bmp":
            case "image/gif":
            case "image/jpeg":
            case "image/tiff":
            case "image/png":
            case "image/webp": {
              return <ImageFileView
                key={"file_" + index}
                size={size}
                upload={() => uploadImageClicked(file)}
                file={file} />;
            }
            default: {
              return <InvalidFileView
                key={"file_" + index}
                size={size}
                remove={(file: File) => removeFile(file)}
                file={file} />;
            }
          }
        })}
      </>
    );
  }

  return (
    <div id="app">
      <div id="content">
        <img
          src="/image/doc.png"
          alt="Document"
          onClick={() => imageClicked()} />
        <input
          type="file"
          multiple={true}
          ref={fileRef}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => fileChanged(event)} />
        <div className="files">
          {renderFile()}
        </div>
      </div>
    </div>
  );
}

interface IFileViewProps {
  file: File;
  size: string;
}

interface IInvalidFileViewProps extends IFileViewProps {
  remove: (file: File) => void;
}

interface IImageFileViewProps extends IFileViewProps {
  upload: (file: File) => void;
}

interface IPdfFileViewProps extends IFileViewProps {
  upload: (file: File, pages: number) => void;
}

function InvalidFileView(props: IInvalidFileViewProps) {
  const { size, file, remove } = props;

  return (
    <div className="file">
      <div className="file-value">
        {file.name}
      </div>
      <div className="file-value">
        {size}
      </div>
      <div className="file-value">
        {file.type}
      </div>
      <div className="file-value">
        <button onClick={() => remove(file)}>
          Remove
        </button>
      </div>
    </div>
  );
}

function ImageFileView(props: IImageFileViewProps) {
  const { file, upload, size } = props;

  return (
    <div className="file">
      <div className="file-value">
        {file.name}
      </div>
      <div className="file-value">
        {size}
      </div>
      <div className="file-value">
        {file.type}
      </div>
      <div className="file-value">
        <button onClick={() => upload(file)}>
          Get Favicon
        </button>
      </div>
    </div>
  );
}

function PdfFileView(props: IPdfFileViewProps) {
  const [pages, setPages] = React.useState<string>("1");
  const { file, upload, size } = props;

  return (
    <div className="file">
      <div className="file-value">
        {file.name}
      </div>
      <div className="file-value">
        {size}
      </div>
      <div className="file-value">
        {file.type}
      </div>
      <div className="file-value">
        <label>
          Sider:
          <input
            type="number"
            className="pages"
            value={pages}
            onChange={(event) => setPages(event.target.value)} />
        </label>
      </div>
      <div className="file-value">
        <button onClick={() => upload(file, parseInt(pages))}>
          Add pages and download
        </button>
      </div>
    </div>
  );
}

export default App;
